.about-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about-title{
    font-family: 'Luckiest Guy', cursive;
    font-size: 65px;
    margin-bottom: 50px;
    color: black;
    text-align: center;
    padding-top: 115px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.about-blurb {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    line-height: 2.0;
    font-size: 24px;
    font-family: 'Nunito';
    margin-bottom: 150px;
    color: #0f0f0f;
    
}
.about-blurb p {
    margin-bottom: 50px;
}
.last-words-about{
    font-weight: 500;
    font-family: 'Permanent Marker', cursive;
    color: #333;
}
.about-span-call{
    cursor: pointer;
    font-weight: 600;
    border-bottom: #0f0f0f;
}
.free-about{
    font-weight: 600;
}
/* MOBILE CSS */
@media only screen and (max-width: 768px) {
    .about-title{
        font-size: 90px;
        padding-top: 225px;
    }
    .about-blurb{
        width: 80%;
        font-size: 34px;
    }
}