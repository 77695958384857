.body {
    display: flex;
    justify-content: space-between;
    margin-top: 115px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 100px;
    
}
.top-left {
    width: 100%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#main-pic-pair{
    width: 50%;
}
/* TEXT */ 
.title, .reviews-title{
    font-family: 'Luckiest Guy', cursive;
    font-size: 65px;
    margin-bottom: 50px;
    color: black;
    text-align: center;
}
.title{
    margin-bottom: 40px;
}
.intro-statement, .top-state{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    width: 100%;   
    color: #333;
    text-align: center;
    font-family: 'Dosis', sans-serif;
}
.bottom-state{
    font-family: 'Luckiest Guy', cursive;
    font-size: 50px;
    margin-bottom: 50px;
    color: black;
    text-align: center;
}
.top-logo{
    width: 17rem;
    border-radius: 25px;
}
/* TOP SECTION SLIDE FUNC */
.top-left {
    position: relative;
    overflow: hidden;
}
.title{
    opacity: 0;
    transform: translateY(-50px); /* Initial position above the screen */
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}
.title {
    animation-name: dropIn;
}
.top-state {
    opacity: 0;
    transform: translateX(50px); /* Initial position to right of the screen */
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}
.top-state {
    animation-name: slideIn;
    animation-delay: 0.5s; /* Delay the slideIn animation slightly */
}
.bottom-state {
    opacity: 0;
    transform: translateX(50px); /* Initial position to right of the screen */
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}
.bottom-state {
    animation-name: slideIn;
    animation-delay: 1s; /* Delay the slideIn animation slightly */
}
@keyframes dropIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
/* before/after pic functionality */
.before-after-pics {
    display: flex;
    justify-content: space-between;
}
.left-b-a {
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.main-picture-pair {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: center;
}
.main-pic-left {
    max-height: 25rem;
    width: 20rem;
    opacity: 0;
    transform: translateX(-50%); /* Start offscreen to the left */
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.main-pic-right {
    max-height: 25rem;
    width: 20rem;
    opacity: 0;
    transform: translateX(90%); /* Start offscreen to the right */
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.slide-in {
    transform: translateX(0); /* Move to the original position */
    opacity: 1;
}
.top-mobile-phone{
    display: none;
}
.top-phone-link{
    display: none;
}
/* MOBILE */
@media only screen and (max-width: 768px) {
    .top-left{
        margin-bottom: 10%;
        margin-right: 0;
    }
    .body{
        margin-top: 30%;
        margin-bottom: 10%;
    }
    .top-logo{
        width: 25rem;
    }
    .top-state{
        font-size: 60px;
    }
    .bottom-state{
        font-size: 65px;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .top-mobile-phone{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 60px;
    }
    .top-phone-link{
        display: block;
        text-decoration: none;
        color: black;
        font-weight: 500;
        font-family: 'Luckiest Guy', cursive;
        font-size: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .call-now-top{
        color: black;
        font-weight: 500;
        font-family: 'Luckiest Guy', cursive;
        font-size: 70px;
        text-align: center;
    }
    .top-yelp-link{
        margin-top: 50px;
        width: 10rem;
        display: flex;
        align-items: center;
    }
}