.services-sec, .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.services {
    margin-bottom: 75px;
    color: #0f0f0f;
    width: 90%;
    font-family: 'Dosis', sans-serif;
    margin-left: 25%;
    align-items: baseline;
}
.services-title{
    margin-top: 100px;
    font-family: 'Luckiest Guy', cursive;
    font-size: 65px;
    margin-bottom: 50px;
    color: black;
    text-align: center;
}
.services-text-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 25px;
}
.services-text {
    font-size: 32px;
    color: #333;
    display: flex;
    align-items: center;
}
/* SERVICES TEXT FUNCTIONALITY */
.services-text-section {
    opacity: 0;
    transform: translateX(80%);
    transition: opacity 1s, transform 1s;
}
.slide-inn {
    animation: slideInn 2s forwards;
} 
@keyframes slideInn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
/* BEFORE & AFTER PICTURE FUNCTIONALITY */
.before-after-pics {
    display: flex;
    justify-content: space-between;
}
.left-b-a {
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.picture-pair {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: center;
}
.b-a-pic-left, .main-pic-left {
    max-height: 25rem;
    width: 20rem;
    opacity: 0;
    transform: translateX(-50%); /* Start offscreen to the left */
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.b-a-pic-right, .main-pic-right {
    max-height: 25rem;
    width: 20rem;
    opacity: 0;
    transform: translateX(90%); /* Start offscreen to the right */
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}
.slide-in {
    transform: translateX(0); /* Move to the original position */
    opacity: 1;
}
.lil-rim{
    width: 4rem;
}
/* MOBILE CSS*/
@media only screen and (max-width: 768px){
    .b-a-pic-right, .b-a-pic-left{
        max-width: 40%;
        max-height: 355px;
    } 
    .services{
        margin-left: 0;
        gap: 50px;
        margin-bottom: 20%;
    }
    .services-title{
        margin-top: 220px;
        font-size: 90px;
    }
    .services-text{
        font-size: 50px;
    }
    .lil-rim{
        width: 5rem;
        height: 5rem;
    }
}