#stickyHeader{
    position: fixed;
    top: 0px;
}
.nav-logo  {
    width: 4.2rem;
    cursor: pointer;
    border-radius: 10px;
}
.header-links-wrapper{
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    height: 70px;
    background-color:#33333396;
    align-items:center;
    letter-spacing: 2px;
    z-index:1;
}
.header-links{
    margin:0px;
    font-family: 'Fjalla One', sans-serif;
    color: white;
    cursor:pointer;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
}
.header-links:hover{
    color:#010000cb
}
.links{
    display: flex;
    justify-content: space-between;
    width: 70vw;
}
/* MOBILE */
@media only screen and  (max-width: 768px) {
    .links{
        display: flex;
        justify-content: space-around;
        width: 70%;
    }
    .header-links{
        font-size: 40px;
    }
    .header-links-wrapper{
        height: 180px;
    }
    .nav-logo{
        width: 10rem;
    }
}