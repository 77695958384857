.footer-container {
    background-color:#33333396;
    display: flex;
}
.footer-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 6%;
    height: 18rem;
}
.foot-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 40%;
    margin-left: 30px;
}
.foot-title{
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Fjalla One', sans-serif;
    font-size: 50px;
}
.exit-statement, .exit-statement-mobile {
    font-family: 'Dosis', sans-serif;
    font-size: 30px;
    text-align: center;
    line-height: 1.5;
}
.exit-statement-mobile{
    display: none;
}
.last-words-footer{
    font-weight: 500;
    font-family: 'Permanent Marker', cursive;
    font-size: 30px;
    text-align: center;
    line-height: 2.5;
}
.contact-foot{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
}
.contact-foot-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.phone-foot{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10%;
    margin-top: 5%;
}
.email-foot{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}
.want-email, .want-phone, .want-message{
    font-size: 35px;
    margin-bottom: 50px;
    font-family: 'Fjalla One', sans-serif;
}
.email-button, .phone-button {
    display: inline-block;
    padding: 15px 30px; 
    background-color: #666666;
    color: white;
    text-decoration: none;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}
.email-button:hover, .phone-button:hover {
    background-color: #cccccc;
}
.phone-button:hover{
    opacity: 20%;
}
.kokachin {
    width: 6rem;
}
.footer-container{
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}
.bottom-footer-wrapper-mobile{
    display: none;
}
.bottom-footer-wrapper{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}
.bottom-footer-container {
    margin-left: 10%;
    width: 25%;   
}
.my-row {
    font-size: 13.5px;
    color: rgb(149, 149, 149);
    margin-bottom: 9px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
}
.languages{
    width: 65%;
    margin-left: 14.5%;
}
.meh{
    width: 70%;
    margin-left: 13%;
}
.year-container {
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 5%;
    display: flex;
    align-content: baseline;
    align-items: baseline;
    flex-wrap: wrap-reverse;
}
.host  {
    color: #3f3f3f;
}
.git, .linked-in{
    width: 3rem;
    display: inline-block;
    background-color: #666666;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}
.git:hover, .linked-in:hover {
    background-color: #cccccc;
}
.git-link, .linked-in-link {
    display: block;
}
.git-linked-cont{
    display: flex;
    gap: 40px;
}
.connect-with-me-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 3%;
}
.mobile-phone, .mobile{
    display: none;
}

/* MOBILE CSS */
@media only screen and (max-width: 768px) {
    .desktop {
        display: none;
    }
    .mobile, .mobile-phone{
        display: flex;
    }
    .footer-wrapper{
        padding-top: 5%;
        padding-bottom: 10%;
        height: auto;
    }
    .footer-container{
        flex-direction: row;
        height: 87rem;
    }
    .bottom-footer-wrapper-mobile{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 25%;
    }
    .bottom-footer-container{
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .foot-title{
        font-size: 75px;
        text-align: center;
        margin-top: 20%;
        margin-bottom: 10%;
    }
    .want-email, .want-phone, .want-message {
        text-align: center;
        margin-bottom: 20px;
        font-size: 52px;
    }
    .contact-foot, .email-foot, .phone-foot {
        align-items: center;
        margin: 0;
        margin-top: 20px;
    }
    .contact-foot{
        justify-content: space-evenly;
        flex-direction: column-reverse;
        margin-right: 30px;
    }
    .my-row{
        font-size: 12px;
    }
    .year-container {
        text-align: center;
        margin-right: 0;
        margin-bottom: 20%;
    }
    .bottom-footer-wrapper{
        display: none;
    }
    .foot-left{
        width: 80%;
    }
    .cal-year{
        font-size: 43px;
    }
    .host{
        font-size: 26px;
    }
    .connect-with-me-wrapper{
        gap: 20px;
    }
    .git, .linked-in{
        width: 7rem;
    }
    .exit-statement{
        font-size: 50px;
    }
    .kokachin{
        width: 10rem;
    }
    .phone-number{
        margin-top: 20%;
    }
}